import {addIndividualExpenses} from "../actions/dependents";

export enum ActionType {
  SIGNIN,
  RESET_LOGIN_ATTEMPTS,
  ACTIVATE_ACCOUNT,
  USER_INFO,
  LIST_TRANSACTIONS,
  GET_CONTRIBUTIONS,
  GET_CONTRIBUTION_HISTORY,
  LIST_BENEFICIARIES,
  LIST_DEPENDENTS,
  SIGNOUT,
  GET_EXPENSE,
  VERIFY_ACCOUNT,
  GET_EMAIL_PREFRENCES,
  UPDATE_EMAIL_PREFRENCES,
  GET_LATER_EXPENSE,
  FORGOT_PASSWORD,
  CHECK_VERIFICATION_TOKEN,
  CHANGE_PASSWORD,
  RESEND_VERIFICATION_MAIL,
  MY_PROFILE,
  UPDATE_PASSWORD,
  ADD_BENEFICIARY,
  ADD_DEPENDENT,
  DEL_DEPENDENT,
  EDIT_DEPENDENT,
  DEL_BENEFICIARY,
  EDIT_BENEFICIARY,
  GET_INDIVIDUAL_DEPENDENTS_CATEGORIES_FOR_EXPENSE,
  ADD_INDIVIDUAL_EXPENSE,
  GET_INVESTMENT_QUESTIONS,
  GET_INVESTMENT_AMOUNT,
  GET_DW_TICKER_SYMBOLS,
  INVESTMENT_QUESTION_ANSWERS,
  GET_INVESTMENT_QA_TYPE,
  CREATE_DW_ORDER,
  INVESTMENT_DASHBOARD_DATA,
  INVESTMENT_STATUS,
  INVESTMENT_EQUITY,
  PARTICIPANT_STATES,
  UPDATE_PROFILE,
  GET_EXPENSE_DETAILS,
  GET_EXPENSE_FILTERS,
  POST_PUBLIC_TOKEN,
  LINKED_BANK_ACCOUNTS,
  CHECK_ACCESS_TOKEN,
  GET_EXPENSE_NEW,
  CASH_STATEMENT,
  INVESTMENT_STATEMENT,
  INVESTMENT_FILE_KEY,
  GET_DW_TRANSACTIONS,
  SELL_EQUITY_POSITIONS,
  PARTICIPANT_NOTIFICATION_STATUS,
  SEND_NOTIFICATION_EMAIL,
  INVESTMONEY,
  IMPORTCONTRIBUTIONS,
  SIGNUP_INDIVIDUAL,
  INDIVIDUAL_BANK_DATA,
  FUND_ACCOUNT_DATA,
  DW_HSA_ACCOUNT_DATA,
  COMMON_DOCUMENT_FILE,
  UPDATEPLAIDSTATUS
}

export enum SponsorActionType {
  SPONSOR_SIGNIN,
  SPONSOR_RESET_LOGIN_ATTEMPTS,
  SPONSOR_ACTIVATE_ACCOUNT,
  SPONSOR_USER_INFO,
  SPONSOR_GET_DASHBOARD,
  SPONSOR_GET_PARTICIPANTS,
  SPONSOR_GET_CONTRIBUTIONS,
  SPONSOR_GET_CONTRIBUTION_HISTORY,
  SPONSOR_LIST_BENEFICIARIES,
  SPONSOR_LIST_DEPENDENTS,
  SPONSOR_SIGNOUT,
  SPONSOR_GET_EXPENSE,
  SPONSOR_VERIFY_ACCOUNT,
  SPONSOR_FORGOT_PASSWORD,
  SPONSOR_NOTIFICATION_DETAIL,
  SPONSOR_NOTIFICATION_READ,
  SPONSOR_CHECK_VERIFICATION_TOKEN,
  SPONSOR_CHANGE_PASSWORD,
  SPONSOR_RESEND_VERIFICATION_MAIL,
  SPONSOR_MY_PROFILE,
  SPONSOR_UPDATE_PASSWORD,
  SPONSOR_ADD_BENEFICIARY,
  SPONSOR_ADD_DEPENDENT,
  SPONSOR_DEL_DEPENDENT,
  SPONSOR_EDIT_DEPENDENT,
  SPONSOR_DEL_BENEFICIARY,
  SPONSOR_EDIT_BENEFICIARY,
  SPONSOR_INDIVISUAL_DATA,
  SPONSOR_PARTICIPANT_PAGINATE,
  SPONSOR_PARTICIPANT_CONTRIBUTIONHISTORY,
  SPONSOR_PARTICIPANT_SEARCH,
  SPONSOR_STATES,
  SPONSOR_GET_DOCUMENTS,
  SPONSOR_ADD_DOCUMENTS,
  SPONSOR_UPDATE_DOCUMENT,
  SPONSOR_DELETE_DOCUMENT,
  SPONSOR_NOTIFICATIONS,
  SPONSOR_GET_EMAIL_PREFRENCES,
  SPONSOR_UPDATE_EMAIL_PREFRENCES,
  SPONSOR_FINCH_ACCESS_TOKEN,
  SPONSOR_FINCH_COMPANY_DATA,
  SPONSOR_FINCH_DIRECTORY_DATA,
  SPONSOR_FINCH_PAYMENT_DATA,
  SPONSOR_FINCH_DATA


}

export interface Action<T> {
  type: ActionType;
  payload: T;
}
