import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Col,
  Card,
  Button,
  Input,
  Checkbox,
  Breadcrumb,
  Steps,
  message,
} from "antd";
import { useActions, investmentAction } from "../../../actions";
import { notifyMe, Loader } from "../../../helper";
import { Utils } from "../../Utils/utils";


const FirstCotent = ({ data, asofdate, setinput, error }) => {
  return (
    <>
      <Col lg={{ span: 20 }} className="mx-auto">
        <div className="fundTransferBodyContent ">
          <div className="manageCardsSliderButton MoveMoneyBtn ">
            <div className="HsaFundBalance ">
              <div className="hsaFundBtn">
                <h3>Investment Value</h3>
                <div className="hsaButtonBorder onlyBorder mb-0">
                  <span className="iconShopHSA"></span>
                  <span className="priceHSAGreen text-left" >$ {data?.investment_value} </span>
                </div>
                <p className="fw-5 mt-2">Fair Market Value <br /> *Current {asofdate && `${'as of ,' + asofdate}`}.</p>
              </div>
              <div className="centerFundTransfer">
                <div className="sendFunfImg"></div>
              </div>

              <div className="hsaFundBtn fundingAmountRightDiv">
                <h3>{Utils.transferamount}</h3>
                <div className="hsaButtonBorder mb-0">
                  <span className="iconShopHSA"></span>
                  <span className="priceHSAGreen ">
                    {" "}
                    <span className="dallarSign">&#x00024;</span>{" "}
                    <Input name="abc" defaultValue="1" style={{ background: 'transparent', padding: '0px 0px 0px 18px', width: '145px', borderRadius: '15px' }} onChange={(e) => setinput(e.target.value)} />{" "}
                  </span>

                  {/* <span className="sellAll">
                    <Checkbox>Sell All</Checkbox>
                  </span> */}
                </div>
                {error && <span style={{ color: 'red' }}>{Utils.amtnotgreatertheninvstamt}</span>}
                <p className="fw-5 mt-2 ">{Utils.amttransfertohsa}</p>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

const SecondCotent = ({ input, executedOrder }) => {
  return (
    <>
      <Col lg={{ span: 20 }} className="mx-auto">
        <div className="transferContent">
          <p className="TransferTxt">
            Confirm transfer of <strong> ${input} </strong> {Utils.hsatoinvstaccount}
          </p>
          <p className="TransferTxt">
          {Utils.confirmingthetransfer}
            <a onClick={executedOrder}><strong > {Utils.transactionhistory}</strong>.</a>
          </p>
        </div>
      </Col>
    </>
  );
};

const ThirdContent = () => {
  return (
    <>
      <Col lg={{ span: 20 }} className="mx-auto">
        <div className="thanksContent">
          <h3 className="ThanksTxt">Success!</h3>
          <h3 className="ThanksTxt">
            {/* Your investment sale will be submitted for trading on the next business day in which the federal Reserve and New York Stock Exchange are both open for business. The amount received for your investment sale may not equal your requested amount due to market value fluctuation.

            <p> Redemption Fees</p>
            <p>The sale of mutual funds may result in redemption fees if trades occur within specific periods established by certain mutual funds. See the mutual fund prospectus for more information on redemption fees</p> */}
          </h3>
        </div>
      </Col>
    </>
  );
};

const { Step } = Steps;


const FundTransfer = (props) => {

  const [current, setCurrent] = React.useState(0);
  const [input, setinput] = React.useState(1);
  const [error, seterror] = React.useState(false);
  const [ischecked, setischecked] = React.useState(false);
  const fundsaction = useActions(investmentAction);
  const [isBackdropVisible, setisBackdropVisible] = React.useState(false);

  useEffect(() => {
    _validateInput(input);
  }, [input]);

  const _validateInput = (input) => {
    if (parseFloat(input) <= parseFloat(0)) {
      seterror(true);
    } else if (parseFloat(input) > parseFloat(props?.dashboardData?.investment_value)) {
      seterror(true);
    } else {
      seterror(false);
    }
  }

  const next = () => {
    setCurrent(current + 1);
    console.log(current + "steps count")
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: "Funds",
      content: <FirstCotent data={props?.dashboardData} asofdate={props?.asofdate} setinput={setinput} error={error} />,
      count: 1,
    },
    {
      title: "Transfer",
      content: <SecondCotent input={input} executedOrder={props?.executedOrder} />,
      count: 2,
    },
    {
      title: "Confirm",
      content: <ThirdContent />,
      count: 3,
    },
  ];


  const sellfunds = async () => {
    try {
      setisBackdropVisible(true);
      let obj = { "amount": input};
      let resp = await fundsaction.selldwequities(obj);
      if (resp?.payload?.orderData?.orderID != '') {
        setisBackdropVisible(false);
        setCurrent(current + 1);
        notifyMe({ method: 'success', message: 'Order placed successfully.' });
        return false;
      } else {
        setisBackdropVisible(false);
        setCurrent(0);
        notifyMe({ method: 'error', message: 'There was some error in creating your order please try again later.' });
        return false;
      }
    } catch (err) {
      setisBackdropVisible(false);
      setCurrent(0);
      notifyMe({ method: 'error', message: 'There was some error in creating your order please try again later.' });
      return false;
    }
  }


  return (
    <>
      <Loader isBackdropVisible={isBackdropVisible} />
      <div className=" ScrollViewMain scrollDashboard moveMoneyWrapper">
        <Card className="MoveMoenyMain">
          <Breadcrumb className="FundTrabsferBreadcrumb" separator=">">
            <Breadcrumb.Item>{Utils.movemoney}</Breadcrumb.Item>
            <Breadcrumb.Item> {Utils.transferfundstohsa} </Breadcrumb.Item>
          </Breadcrumb>
          <div className="fundtransferBodyMain">
            <Col lg={{ span: 20 }} className="mx-auto">
              <h3 className="fundTransferHeading">
                Transfer <strong>Funds </strong> to Your<strong> HSA </strong>
              </h3>
              <p className="fundDescription">
               {Utils.sellinvst}
              </p>
            </Col>
          </div>

          <div className="fundTransferStep">
            <Steps current={current}>
              {steps.map((item, i) => (
                <Step icon={item.count} key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>



            {current > 0 &&

              <Col lg={{ span: 20 }} className="mx-auto" style={{ display: 'flex' }}>
                {current === 1 && <Checkbox style={{ float: 'left' }} onChange={(event) => {
                  setischecked(event.target.checked);
                }} />}
                <p className="Fund_disclouser mx-auto">
                  {current === 1 && <> <strong>Disclosure:</strong> Your investment sale will be submitted for trading on the next business day in which the Federal Reserve and New York Stock Exchange are both open for business.{" "} The amount received for your investment sale may not equal your requested amount due to market value fluctuation.</>}

                  {current === 2 && <> <strong>Disclosure:</strong> Your investment sale will be submitted for trading on the next business day in which the federal Reserve and New York Stock Exchange are both open for business. The amount received for your investment sale may not equal your requested amount due to market value fluctuation.</>}

                  <br />
                  <br />
                  <strong> {Utils.redemptionfees}</strong>
                  <p>{Utils.saleoffundsresults}</p>
                </p>

              </Col>}

            <div className="steps-action stepBottomBtn">
              {current === 0 && (
                <Button
                  style={{ margin: "0 8px" }}
                  className="buttonLeftInvest activeBorder"
                  disabled="true"
                >
                  Cancel
                </Button>
              )}
              {current > 0 && (
                <>
                  {current < 2 && (
                    <Button
                      style={{ margin: "0 8px" }}
                      onClick={() => prev()}
                      className="buttonLeftInvest activeBorder"
                    >
                      Back
                    </Button>
                  )} </>
              )}
              {current === 0 && (
                <>
                  {current < 2 && (
                    <Button
                      type="primary"
                      onClick={() => next()}
                      className="buttonLeftInvest active"
                      disabled={error === true ? true : false}
                    >
                      {current === 0 ? 'Transfer' : 'Transfer'}
                    </Button>
                  )

                  } </>
              )}

              {current === 1 && (
                <>
                  <Button
                    type="primary"
                    onClick={() => sellfunds()}
                    className="buttonLeftInvest active"
                    disabled={ischecked === true ? false : true}
                  >
                    Transfer
                  </Button>
                </>
              )}
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default withRouter(FundTransfer);
