export const Utils = {
  signin: 'Sign in ',
  welcomeMsg: 'Welcome to SavingsOak',
  activeAccount:'Activate Account',
  createAccount:'Create Account',
  forgotPassword:'Forgot password?',
  maintenance:'We apologize for the inconvenience. We are performing some maintenance. If you need to you can always ',
  contactUs:'contact us',
  checkback:', otherwise check back soon!',
  savingsOak:'The SavingsOak Team',
  backSoon:'We will be back soon!',
  forgotyouPassword:'Forgot you password?',
  receiveEmail:'You will receive an email with a link to reset your password.',
  transactionDetails:'Transaction Details',
  mostRecent:'Most Recent',
  verifyyouridentity:'Verify your identity',
  completeVerification:'For your security, please provide the following information to complete verification.',
  continue:'Continue',
  cancel:'Cancel',
  save:'Save',
  administratorInformation :'Administrator Information',
  addAdministrator:' Add Administrator',
  deleteMessage:'Are you sure you want to delete',
  asAdministratorto:'as an Administrator to',
  undoneMessage:'? This action cannot be undone.',
  deleteAdministrator:'Yes, Delete Administrator',
  companyInformation:'Company Information',
  uploadlogo:'Upload logo',
  edit:'Edit',
  emailSettings:'Email Settings',
  sendactivationLink:'Send Activation Link',
  accountActivation:'Account Activation',
  emailassociated:'Enter the email associated with your SavingsOak HSA Administration account.',
  contactsupport:'If you are unsure of the email address, contact support@savingsoak.com',
  activationlink:'Activation Link Sent' ,
  checkspamfolder:'Activation link has been emailed to you. If you don’t see it, please check Spam folder. The email must match the one associated with your account.',
  resetyourpassword:'Reset your password',
  contributionhistory:'Your contribution history will be shown here',
  contributionsthisyear:' Total contributions for this year',
  contributionsthismonth:'My contribution this month',
  monthemployercontribution:'this_month_employer_contribution',
  myemployercontribution:'My employers contribution this month',
  linkbankinformation:'Lets link your bank information',
  bankaccount:'Add a Bank Account',
  expeditedreimbursements:' Please add a bank account for the faster, safer transfer of funds from your bank to your Health Savings Account and expedited reimbursements. ',
  newaccount:' Add New Account',
  reviewinfo:'Review your Information',
  expsummary:'Expense Summary',
  noexp:'No expenses for this year',
  eligibleexp:'Your eligible expenses will be shown here',
  monthlycontri:'Your monthly contribution will show here',
  contrithismonth:'Your contribution this month',
  msgcenter:'Message Center',
  msgabouttrans:'Messages about statements, transactions, and year-end tax statements will appear here.',
  investments:'Investments',
  expandview:'Expand View',
  investmentvalue:'Investment Value',
  availabletoinvest:'Available to Invest',
  recenttrans:'Recent Transactions',
  thisyear:'This Year',
  thismonth:'This Month',
  thisweek:'This Week',
  splunkcontrithismonth:'Splunk’s contribution this month',
  yearendtaxstatements:'Your year-end tax statements are now ready',
  markasread:'Mark as read',
  notifications:'Notifications',
  investmentstatements:'Investment Statements',
  taxstatements:'Tax Statements',
  otherstatements:'Other Statements',
  taxdocuments:'Tax Documents',
  entercategory:'Enter Your category',
  draganddrop:'Drag and drop files here',
  browsefiles:'Browse Files',
  viewclaimdetails:' View claim details',
  viewreciept:'View reciept',
  downloadclaim:'Download claim',
  printclaim:'Print claim',
  recieveyourreimbursement:'You must set up your bank account in order to recieve your reimbursement.',
  setbankaccount:'Click here to set up your bank account.',
  recommended:'Recommended',
  reimburselater:'Reimburse Later',
  projectionssubjectto:'Projections are subject to',
  choosereimbtype:'Choose Reimbursement Type',
  reimburse:'Reimburse',
  after2years:'After 2 Years',
  after5years:'After 5 Years',
  after7years:'After 7 Years',
  projectionssubjecttochange:'Projections are subject to change',
  needmoreinfo:'It looks like we need some more information.',
  bankaccounttobelinked:'In order to process your reimburesment a bank account has to be linked to your account.',
  linkaccount:'Link Account',
  noreceiptimagefound:'No Receipt Image Found',
  resetpassword:'Reset password',
  viewall:'View All',
  investtogrowyourmoney:'Invest to Grow Your Money',
  lookat401kplan:'Look at Your HSA as a 401k plan',
  planningfomexperts:'Read how HSAs help retirement planning from experts.',
  retirementplanning:'HSA and retirement planning',
  meetretirementgoal:'Meet your retirement goal with HSA',
  learnabouthsa:'Learn About HSA',
  poststolearnhsa:'Useful posts, guides, and tutorials to learn about your HSA.',
  medicalexp:'Qualified Medical Expenses',
  tipstogetmostofhsa:'Useful tips and tricks to help you get the most out of your HSAs',
  shoeboxyourreceipts:'Shoebox your receipts',
  planwithmedicalexp:'Plan Wisely With Your Qualified Medical Expenses',
  receiptsforreimbursement:'With all the qualified medical expenses you get tax-free, it can be tempting to reimburse yourself right away. However, pay for minor costs out of pocket if you can. Save and invest the HSA dollars for the larger ones later to maximize your HSA benefits. <br /> A complete list of qualified medical expenses is available on the IRS website - publications <a href="https://www.irs.gov/forms-pubs/about-publication-502" target="_blank">502</a> and <a href="https://www.irs.gov/forms-pubs/about-publication-969" target="_blank">969</a>. You must save the out-of-pocket payment receipts for reimbursement later on. You can do that by clicking on the “Expenses” menu in the main navigation.',
  hsadollors:'A simple fact remains: most HSA participants dont know they can invest their HSA dollars. They accumulate cash in their accounts and often unnecessarily spend money.',
  hsacontributions:'You can save and grow the HSA contributions for the future. While most employees view their 401(k) account as a long-term retirement investment vehicle, they need to pay more attention to the rewarding investment opportunity of their HSA. You will be able to learn more about ',
  advt:'Taking full advantage of both accounts tax benefits would be best.',
  hsaoptimize:'HSA and 401(k) - How to Optimize',
  maxyourbenefits:'you can read about how these two accounts can work together to maximize your benefits and strengthen your retirement.',
  deductible:'Deductible',
  amtbeforeinsurance:'The amount you pay before your health insurance plan begins to pay',
  premium:'Premium',
  amtpayforplan:'The amount you pay every month for the plan you’ve chosen. Individuals and families will pay this amount whether or not they visit a doctor.',
  copay:'Co-pay',
  amtofmoney:'The fixed amount of money you pay for a medical service. This amount can vary for the type of service you receive.',
  coinsurance:'Co-insurance',
  metyourplan:'Once you have met your plan’s deductible, you will begin to pay a shared amount of the cost with your health insurance every time you get a medical service. This is generally presented as a percentage.',
  outofpocket:'Out-of-Pocket Maximum',
  protectinhealthcrisis:'To protect you in case you have a health crisis, there is something called an out-of-pocket maximum. This is the absolute total you can be charged for medical services in a year. Once you reach the out-of-pocket maximum, your health insurance plan begins to pay 100% of the costs. This does not include premiums, out-of-network providers, and costs that your plan does not cover.',
  impofhsa:'The Importance of an HSA in Your Retirement Planning',
  connectionofhealth:'What is the connection between health and financial wellness? It seems there is a strong one. Money ranks as the number one source of stress for people. Moreover, there is a link between financial wellness and health. According to EBN, 72% of people worried about affording healthcare in retirement followed closely by 65% of people who worried about outliving their retirement savings. But what if you are a person who con tributes every month to your 401k and IRA accounts? If you aren’t considering a health savings account (HSA) you could be missing an extremely beneficial retirement tool for your medical costs.',
  hsahelpinretirement:'How Can HSAs Help You with Your Retirement Goal',
  hsaableplanning:'HSAs allow you to start planning for medical costs – today and in retirement. Since they are one of the most tax-advantaged accounts around, as soon as you sign up and start contributing, you are saving taxes on your qualified medical expenses.However, while a lot of people use their HSA as a way to pay current medical expenses, one way to see how powerful an HSA can be is to start putting aside money in your account for your retirement medical expenses. One way you can do this is by paying for some of your current medical expenses out-of-pocket and leaving the money to grow in your account. Since any money left in your HSA will roll over, you don’t have to worry about spending it all by the end of the year as you would with a Flexible Spending Account (FSA). Another option is to hang on to your current receipts, then reimburse yourself years later when you’ve accumulated a larger sum in your account.',
  hsabalance:'HSA Balance',
  advisortoolmanaged:'Your portfolio is being managed for you by our advisor tool. You can change your allocations by adjusting your risk profile through your account settings at any time.',
  portfolioallocations:'Portfolio Allocations',
  transferamount:'Transfer Amount',
  amtnotgreatertheninvstamt:'Amount cannot be greater then invested Amount.',
  amttransfertohsa:'This amount will be transferred to your HSA Cash Account.',
  hsatoinvstaccount:'from your HSA to your investments account',
  confirmingthetransfer:'By confirming this transfer, you are selling investments and depositing funds into your HSA.<br /> You can view this in the',
  transactionhistory:'Transaction History',
  movemoney:'MOVE MONEY',
  transferfundstohsa:'Transfer Funds to Your HSA ',
  sellinvst:' Sell your investment positions and transfer from your HSA investment account to your HSA Cash account.',
  redemptionfees:'Redemption Fees',
  saleoffundsresults:'The sale of mutual funds may result in redemption fees if trades occur within specific periods established by certain mutual funds. See the mutual fund prospectus for more information on redemption fees',
  transactions:'Transactions',
  funddistribution:'Fund Distribution',
  tradereview:'Trade Review',
  purchasesondesiredallocations:'The following purchases will be made based on your desired allocations.',
  loremipsum:'Lorem ipsum dolor sit amet,consectetur adipiscing elit. Curabitur gravida sit amet lacusvitae vulputate. Phasellus eleifend purus justo, a semper velitpretium ut. Nunc lobortis lacus et dignissim sagittis. Donecfermentum quis ert quis posuere. Nam congue dapibus ante, etornare odio. Suspendisse erat nunc, efficitur et urna non,molestie pellentesque nunc.{" "}',
  pendingtrades:'Pending Trades',
  tradedlistedforexecution:' Trades listed below will be submitted for execution upon market open unless cancelled while pending. Share amounts listed below are an estimate until trade is executed at actual market share shares.',
  july2020:'as of July 29, 2020',
  transactiondetails:'Transaction Details',
  tradeslistedsubmitforexecution:' Trades listed below will be submitted for execution uponmarket open unless cancelled while pending. Share amountslisted below are an estimate until trade is executed atactual market share price.',
  pendingactivity:'Pending Activity',
  availablehsabalance:'Available HSA balance to invest',
  currenthsabalance:'Your current HSA balance is $2,570.93.You are required to maintain a minimum balance of $1,000 in your HSA.',
  amounttransferfromhsatoaccount:'This amount will be transferred from your HSA to your investment account.',
  hsatransfertoinvstaccount:' Your HSA transfer was submitted to your investment account.',
  investhsadollars:'Invest Your HSA Dollars',
  movemoneyfromhsatoaccount:'Move money from your HSA to your investment account in a few easy steps.Vitae vulputate.Phasellus eleifend purus justo,a semper velit pretium ut. Nunc lobortis dignissim sagittis.',
  buildingaportfolio:' Building a comprehensive portfolio begins with understanding you, your financial outlook, and your long-term financial goals. Answer the next few questions to help us build a customized potential portfolio.',
  managedinvestments:' Managed Investments',
  waittillsettingquestions:'Please wait we are setting questions up... Do not press Back or Refresh',
  consideredmediacalbills:'You must take into consideration near-term medical bills.',
  suggestbalancedportfolio:'Based on your age, responses and associated risk, we suggest the following balanced portfolio allocation for you. You can, however, review and choose any of the other portfolios.',
  investingamount:'Investing Amount',
  investhsabalance:'Investable HSA balance',
  waitwhileprocessing:'Please wait we are processing your information... Do not press Back or Refresh',
  invest:'INVESTMENTS',
  dolorsitamet:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.',
  investing:'Investing',
  howyouwant:'Choose how you want to',
  invst:'Invest',
  frontcard:' This is the front of the card.',
  backcard:'This is the back of the card.',
  congrats:'Congratulations',
  investplatform:'The HSA Investment platform is an excellent way to build your health savings. To get started, check your Available to Invest amount below, then select from the stocks and funds or search our entire library of available options.',
  donthaveenoughcash:'You don’t have enough cash.',
  pleasewait:'Please wait...',
  linkedaccounts:'Linked Accounts',
  addbankaccount:'Add a Bank Account',
  linkyourbank:'SavingsOak uses Plaid to link your bank',
  transferyourinfo:'Transfer of your information is encrypted end-to-end',
  credentialsnotaccessible:' Your credentials wil never be made accessible to this application',
  selecttocontinue:' By selecting to "Continue" you agree to the{" "}',
  beneficiaries:'Beneficiaries',
  addbeneficiary:'Add Beneficiary',
  editdependent:' Edit Dependent',
  deletedependent:'Delete Dependent',
  dependents:'Dependents',
  adddependent:'Add Dependent',
  statementprefer:'Statement Preferences',
  payrollnotifications:'Payroll Notification',
  savingopportunites:'Savings Opportunities',
  securitypreferences:'Security Preferences',
  generalsupportupdates:'General Support Updates',
  investmentopportunities:'Investment Opportunities',
  savingproductupdates:'SavingsOak Product Updates',
  accountinfo:'Account Info',
  accountnumber:'Account Number',
  notedownnumber: 'Please write down your HSA Account details!',
  bankdelinkmessage: 'Are you sure you want to delink the bank account ?',
  confirmdelink: 'Confirm Delink ?',
  delinkformName: 'Delinkform'

}

