import { ActionType } from "../model";
import { notifyMe } from "../helper";

export const getInvestmentsQuestions = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_investment_questions_answers', {}, getState().auth.access_token);
		return dispatch({ type: ActionType.GET_INVESTMENT_QUESTIONS, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getInvestment', method: 'error', message: 'Error', description: error });
	}
};

export const getIndivisualInvestmentAmount = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_individual_investments_amounts', {}, getState().auth.access_token);
		return dispatch({ type: ActionType.GET_INVESTMENT_AMOUNT, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getInvestment', method: 'error', message: 'Error', description: error });
	}
};

export const getDWtickerSymbols = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_dw_portfolios_and_ticker_symbols', {}, getState().auth.access_token);
		return dispatch({ type: ActionType.GET_DW_TICKER_SYMBOLS, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getInvestment', method: 'error', message: 'Error', description: error });
	}
};

export const getinvestmentqastatus = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_individual_investment_qa_type', {}, getState().auth.access_token);
		return dispatch({ type: ActionType.GET_INVESTMENT_QA_TYPE, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getInvestment', method: 'error', message: 'Error', description: error });
	}
};


export const savequestionanswers = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('update_individual_investment_qa_type', data, '', getState().auth.access_token);
		return dispatch({ type: ActionType.INVESTMENT_QUESTION_ANSWERS, payload: resp });
	} catch (error) {
		notifyMe({ key: 'getInvestment', method: 'error', message: 'Error', description: error });
	}
};

// export const createDworder = (data) => async (dispatch, getState, api) => {
// 	try {
// 		let resp = await api.post('create_dw_order', data, '', getState().auth.access_token);
// 		return dispatch({ type: ActionType.CREATE_DW_ORDER, payload: resp });
// 	} catch (error) {
// 		notifyMe({ key: 'getInvestment', method: 'error', message: 'Error', description: error });
// 	}
// };

export const createDworder = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('attach_dw_account_to_portfolio_id', data, '', getState().auth.access_token);
		return dispatch({ type: ActionType.CREATE_DW_ORDER, payload: resp });
	} catch (error) {
		notifyMe({ key: 'getInvestment', method: 'error', message: 'Error', description: error });
	}
};

export const getinvestmentsdashboard = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_purchased_funds', {}, getState().auth.access_token);
		return dispatch({ type: ActionType.INVESTMENT_DASHBOARD_DATA, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getInvestment', method: 'error', message: 'Error', description: error });
	}
};


export const getinvestmentequity = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_dw_account_summary', {}, getState().auth.access_token);
		return dispatch({ type: ActionType.INVESTMENT_EQUITY, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getInvestment', method: 'error', message: 'Error', description: error });
	}
};


export const getinvestmentstatus = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_investment_statuses', {}, getState().auth.access_token);
		return dispatch({ type: ActionType.INVESTMENT_STATUS, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getInvestment', method: 'error', message: 'Error', description: error });
	}
};

export const getinvestmentequityNew = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.get('get_dw_account_summary_latest', {}, getState().auth.access_token);
		return dispatch({ type: ActionType.INVESTMENT_EQUITY, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getInvestment', method: 'error', message: 'Error', description: error });
	}
};


export const selldwequities = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('create_dw_transfer', data, '', getState().auth.access_token);
		return dispatch({ type: ActionType.SELL_EQUITY_POSITIONS, payload: resp.data });
	} catch (error) {
		notifyMe({ key: 'getInvestment', method: 'error', message: 'Error', description: error });
	}
};

export const investmoney = (data) => async (dispatch, getState, api) => {
	try {
		let resp = await api.post('invest_money', data, '', getState().auth.access_token);
		return dispatch({ type: ActionType.INVESTMONEY, payload: resp });
	} catch (error) {
		notifyMe({ key: 'getInvestment', method: 'error', message: 'Error', description: error });
	}
};


// export const getDwTransactions = (data) => async (dispatch, getState, api) => {
// 	try {
// 		let resp = await api.get('get_dw_transactions',  data, '', getState().auth.access_token);
// 		return dispatch({ type: ActionType.GET_DW_TRANSACTIONS, payload: resp.data });
// 	} catch (error) {
// 		notifyMe({ key: 'getInvestment', method: 'error', message: 'Error', description: error });
// 	}
// };



