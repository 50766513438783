import React, { useState, useEffect } from "react";
import { Tabs, Menu, Dropdown } from "antd";
import { withRouter } from "react-router-dom";
import { useActions, investmentAction } from "../../../actions";
import moment from 'moment';
import momentTz from 'moment-timezone';
import InvestmentOverview from '../../../components/investment/investmentdashboard/dashboard';
import MoveMoney from "../FundTransfer/FundTabs";
import PendingTrade from "../../../components/investment/Orders/pending_orders";
import ExecutedTrade from "../../../components/investment/Orders/executed_orders";
import AllActivity from "../../../components/investment/Orders/AllActivity";

const { TabPane } = Tabs;

const InvestmentDashboard = ({ type }) => {
  const investmentcallAction = useActions(investmentAction);
  const [dashboardData, setdashboardData] = useState([]);
  const [investmentequity, setInvestmentequity] = useState([]);
  const [typeofchart, settypeofchart] = useState('week');
  const [asofdate, setasofdate] = useState('');
  const [activeTab, setactiveTab] = useState('1');
  const [dwAccountNumber, setDwAccountNumber] = useState();
  const [showstate, setshowstate] = useState('processed');
  const [equityCategory, setEquaityCategory] = useState([]);
  const [investmentdata, setinvestmentdata] = useState([]);
  const [portfolioname, setportfolioname] = useState('');

  useEffect(() => {
    _fetchDashboardData();
  }, []);

  const _fetchDashboardData = async () => {
    let tz = momentTz.tz.guess();
    const allPromise = Promise.all([investmentcallAction.getinvestmentequity(), investmentcallAction.getinvestmentequityNew()])
    try {
      const lists = await allPromise;
      if(lists && lists.length > 0) {
        setdashboardData(lists[0]?.payload ?? {});
        setinvestmentdata(lists[1]?.payload ?? {});
        setportfolioname(lists[0]?.payload?.portfolio_name ?? '');
        setDwAccountNumber(lists[0]?.payload?.dw_account_number ? 'Investment Account Number: ' + lists[0]?.payload?.dw_account_number : 'There is some error in pulling account number');
        const equityPost = await getarray(lists[1]?.payload?.equityPositions ?? [])
        setInvestmentequity(equityPost);
        if(lists[0]?.payload  && lists[0]?.payload?.lastUpdated) {
          let time = moment(lists[0]?.payload?.lastUpdated).format('llll') + ' (' + momentTz().tz(tz).format('z') + ')';
          setasofdate(time ?? '')
        } else {
          setasofdate('')
        }
        // callInterval();
      }
    } catch (error) {
      setdashboardData({});
      setinvestmentdata({});
      setportfolioname('');
      setDwAccountNumber('');
      setasofdate('');
      setInvestmentequity([]);
      // console.log(error)
    }
  }

  const getarray = (arrval) => {
    let arr = [];
    arrval.map((val, i) => {
      arr.push({
        key: i,
        name: val.name ? val.name : '',
        ticker: val.symbol ? val.symbol : '',
        research: val.url ? val.url : '',
        image: val.image ? val.image : '',
        OpenQty: val.openQty ? val.openQty : '',
        cost: val.costBasis ? val.costBasis : '',
        mktprice: val.mktPrice ? val.mktPrice : '',
        marketvalue: val.marketValue ? val.marketValue : '',
        marketprice: val.mktPrice ? val.mktPrice : '',
        unrealizedPL: val.unrealizedPL ? val.unrealizedPL : '',
        mktPrice: val.mktPrice ? val.mktPrice : '',
        value: val.close ? val.close : '',
        asset_class_morningstar_category: val.asset_class_morningstar_category ? val.asset_class_morningstar_category : '',
      });
    });
    return arr;
  }

  // const callInterval = () => {
  //   window.setInterval(function () {
  //     _fetchDashboardData();
  //   }, 300000);
  // }

  const callback = (key) => {
    setactiveTab(key)
    if (key ==1 || 3 || 4){
      window.history.replaceState(null, "", "/investment_choices");
    }
    if(key == 2){
      window.history.replaceState(null, "", "/all-activity");
    }
  }

  const pendingOrder = () => {
    setshowstate('pending');
    setTimeout(function(){
      window.history.replaceState(null, "", "/pending-orders");
    }, 100)
  }

  const allActivity = () => {
    setshowstate('allActivity');
    setTimeout(function(){
      window.history.replaceState(null, "", "/all-activity")
    }, 100);
  }

  const txtField = React.useRef(null);

  const executedOrder = () => {
    setshowstate('processed')
    window.history.replaceState(null, "", "/executed-orders")
  }

  const menu = (
    <Menu>
       <Menu.Item>
        <a onClick={allActivity} href="javascript:void();"  inputRef={txtField} >
          All Activity
        </a>
      </Menu.Item>
      <Menu.Item  >
        <a onClick={executedOrder} href="javascript:void();">
          Executed Orders
        </a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={pendingOrder} href="javascript:void();">
          Pending Orders
        </a>
      </Menu.Item>
    </Menu >
  );

  const changeAllActivity = () => {
    setactiveTab('2');
    setshowstate('allActivity');
    window.history.replaceState(null, "", "/all-activity")

  }

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        onChange={callback}
        activeKey={activeTab}
        className="investmentTabs"
      >
        <TabPane abPane tab="Overview" key="1" >
          <InvestmentOverview
            asofdate={asofdate}
            dashboardData={dashboardData}
            typeofchart={typeofchart}
            settypeofchart={settypeofchart}
            investmentequity={investmentequity}
            dwAccountNumber={dwAccountNumber}
            equityCategory={equityCategory}
            Investmentdata={investmentdata}
            portfolioname={portfolioname}
          />
        </TabPane>
        <TabPane abPane tab={
          <Dropdown overlay={menu}>
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              Transactions
            </a>
          </Dropdown>} key="2"  >
          <>
            {
              showstate == 'processed' ? 
                <ExecutedTrade />
              : showstate == 'pending' ? 
                <PendingTrade />
              : showstate == 'processed' ? 
                <ExecutedTrade /> 
              : showstate == 'allActivity' ? 
                <AllActivity /> 
              : ''
            }
          </>
        </TabPane>
        <TabPane tab="Move Money" key="3">
          <MoveMoney
            asofdate={asofdate}
            dashboardData={dashboardData}
            executedOrder={changeAllActivity}
          />
        </TabPane>
        {/* <TabPane tab="Documents" key="4">
        </TabPane>
        <TabPane tab="Account Settings" key="5">
        </TabPane> */}

      </Tabs>

    </>
  );
};

export default withRouter(InvestmentDashboard);
