import { createBrowserHistory, createHashHistory } from "history";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import { config } from 'dotenv';
import rootReducer from "./reducers";
import { ActionType } from '../model';
import { Api } from "./api-fetch";
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

config();

const API_URL = 'https://demoapi.savingsoak.com/api';
const dev = process.env.REACT_APP_FRONTENDLOG === 'true';

// Redux Logger
const actionTypeEnumToString = (action) => 
  typeof action.type === 'number' && ActionType[action.type] 
    ? { type: ActionType[action.type], payload: action.payload }
    : action;

const logger = createLogger({ actionTransformer: actionTypeEnumToString });

// Redux thunk
const api = new Api(API_URL);

const thunkMiddleware = thunk.withExtraArgument(api);

// Apply middleware conditionally
const middleware = dev ? [logger, thunkMiddleware] : [thunkMiddleware];
let appliedMiddleware = applyMiddleware(...middleware);

// Redux devtool
const composeEnhancers = composeWithDevTools({ actionSanitizer: actionTypeEnumToString });

if (dev) {
  appliedMiddleware = composeEnhancers(appliedMiddleware);
}

// Redux persist
const persistConfig = {
  key: "root",
  storage,
  version: 1,
  whitelist: ["auth", "sponsorauth"],
  debug: dev,
};

// History
const history = window.matchMedia('(display-mode: standalone)').matches
  ? createHashHistory()
  : createBrowserHistory();

const persistedReducer = persistReducer(persistConfig, rootReducer());

const configureStore = () => {
  const store = createStore(persistedReducer, {}, appliedMiddleware);
  const persistor = persistStore(store);
  return { store, persistor };
};

export default configureStore;
export { history };
